<template>
  <div class="dealer-info-page">
    <div class="back-wrapper">
      <router-link to="/dealers" class="back">
        К списку производителей
      </router-link>
    </div>

    <!-- <a-table
      bordered
      v-if="dealer"
      :columns="columns"
      :data-source="dealers"
      :pagination="false"
    ></a-table>

    <div class="info">
      <a-button class="button">
        <router-link :to="`/dealer/${this.dealerId}/buyers/list`">
          Список дилеров
        </router-link>
      </a-button>
      <br />
      <a-button class="button">
        <router-link :to="`/dealer/${this.dealerId}/buyers/reports`">
          Отчеты по дилерам
        </router-link>
      </a-button>
      <br />
      <a-button class="button">
        <router-link to="/reports/period">
          Отчеты по производителю (период)
        </router-link>
      </a-button>
      <br />
      <a-button class="button">
        <router-link to="/reports/dynamic">
          Отчеты по производителю (динамика)
        </router-link>
      </a-button>
    </div> -->
    <DealerData />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import DealerData from "@/components/dealers/DealerData"

export default {
  name: "DealerInfo",
  components: {
    DealerData,
  },
  computed: {
    ...mapGetters({
      dealer: "dealers/currentDealer",
      columns: "dealers/columns",
    }),
    dealers() {
      return [this.dealer]
    },
    dealerId() {
      return this.$route.params.id
    },
  },
  methods: mapActions({
    fetchDealer: "dealers/fetchDealerById",
  }),
  watch: {
    "$route.params": {
      handler(params) {
        this.fetchDealer(parseInt(params.id))
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.dealer-info-page {
  .back-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  .info {
    margin-top: 20px;

    .button {
      margin-top: 10px;
    }
  }
}
</style>
